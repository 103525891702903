import { TextField, Button } from "@mui/material";
import React, { useState } from "react";
import { useFormControls } from "./ContactFormControls";

const inputFieldValues = [
    {
        name: "fullName",
        label: "Full Name",
        id: "my-name"
    },
    {
        name: "email",
        label: "Email",
        id: "my-email"
    },
    {
        name: "phone",
        label: "Phone",
        id: "my-phone"
    },
    {
        name: "message",
        label: "Message",
        id: "my-message",
        multiline: true,
        rows: 10
    }
];

export const ContactForm = () => {
    const [sendSuccess, setSendSuccess] = useState<boolean>(false);
    const {
        handleInputValue,
        handleFormSubmit,
        formIsValid,
        errors
    } = useFormControls({setSendSuccess});
    if(sendSuccess)
        return (
            <div style={{maxWidth: "800px"}}>
                <span>Thank you for your inquiry. We will response shortly.</span>
            </div>
        );
    return (
        <div style={{maxWidth: "800px"}}>
            <span>Send a Message</span>
            <form autoComplete="off" onSubmit={handleFormSubmit}>
                {inputFieldValues.map((inputFieldValue, index) => {
                    return (
                        <TextField
                        key={index}
                        onChange={handleInputValue}
                        onBlur={handleInputValue}
                        name={inputFieldValue.name}
                        label={inputFieldValue.label}
                        error={errors[inputFieldValue.name]}
                        multiline={inputFieldValue.multiline ?? false}
                        fullWidth
                        rows={inputFieldValue.rows ?? 1}
                        autoComplete="none"
                        {...(errors[inputFieldValue.name] && {
                            error: true,
                            helperText: errors[inputFieldValue.name]
                        })}
                        />
                    );
                })}
                <Button
                    variant="contained"
                    type="submit"
                    color="secondary"
                    disabled={!formIsValid()}
                >
                    Send Message
                </Button>
            </form>
        </div>
    );
};
