import Navigation from "../Navigation/Navigation";
import SitePage from "../SitePage/SitePage";
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ShopIcon from '@mui/icons-material/Shop';
import ContactPageIcon from '@mui/icons-material/ContactPage'
import { useState } from "react";
import SiteSection from "../SitePage/SiteSection/SiteSection";

import Home from '../../resources/content/Home/Home.md';
import About from '../../resources/content/About/About.md';
import Services from '../../resources/content/Services/Services.md';
import Contact from '../../resources/content/Contact/Contact.md';
import { ContactForm } from "../SitePage/ContactForm/ContactForm";

export function PageRouter({ logo, siteTitle, ...props}: any) {

    const [currentPage, setCurrentPage] = useState<string>("Home");

    return (
        <>
            <Navigation logo={logo} siteTitle={siteTitle} currentPage={currentPage} setCurrentPage={setCurrentPage}>
                <SitePage name="Home" icon={HomeIcon} >
                    <SiteSection MarkdownSection={Home} />
                </SitePage>
                <SitePage name="About" icon={InfoIcon} >
                    <SiteSection MarkdownSection={About} />
                </SitePage>
                <SitePage name="Services" icon={ShopIcon} >
                    <SiteSection MarkdownSection={Services} />
                </SitePage>
                <SitePage name="Contact" icon={ContactPageIcon} >
                    <SiteSection MarkdownSection={Contact} />
                    <ContactForm />
                </SitePage>
            </Navigation>
        </>
    );
}

export default PageRouter;