import { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";

export function SiteSection({MarkdownSection, ...props}: any) {
    const [markdown, setMarkdown] = useState<string>("");
    
    useEffect(() => {
        fetch(MarkdownSection)
            .then(resource => resource.text())
            .then(text => setMarkdown(text));
    })
    return (
        <>
            <ReactMarkdown>
                {markdown}
            </ReactMarkdown>
        </>
    );
}

export default SiteSection;