import './App.css';
import PageRouter from './components/PageRouter/PageRouter';
import Logo from './resources/images/mwlogo.png';

const SITE_NAME = "Annie B's Miracle Walker";

function App() {
  return (
    <>
      <PageRouter logo={Logo} siteTitle={SITE_NAME} />
    </>
  );
}

export default App;
