import NavigationFrame from "./NavigationFrame/NavigationFrame";

export function Navigation(props: any) {
    return (
        <>
        <NavigationFrame
            {...props}
        >
            { props.children }
        </NavigationFrame>
        </>
    );
}

export default Navigation;