import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import Paws from '../../resources/images/paw_prints_hr.png';

export interface SitePageProps {
    name: string,
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
    home?: boolean
}

export function SitePage(props: any) {
    return (
        <>
            <div style={{maxWidth: "900px"}}>
                <br />
                <div><h1>{props.name}</h1></div>
                <hr style={{display: "block", border: "none", height: "20px", backgroundImage: `url(${Paws}`}} />
                { props.children }
            </div>
        </>
    );
}

export default SitePage;