import axios from "axios";
import { useState } from "react";

const endpoint = "https://mockups.miley-family.com/send_message.php";

const PostContactForm = async (
  values: any,
  successCallback: any,
  errorCallback: any
) => {
    let success = false;
    await axios
        .post(endpoint, values, {
            headers: { 'Content-Type': 'application/json' },
        })
        .then((body) => {
            success = (body.data === 1);
            console.log(`Success: ${success}, body.data: ${body.data}`);
        });
    if (success) successCallback();
    else errorCallback();
};

const initialFormValues = {
    fullName: "",
    email: "",
    phone: "",
    message: "",
    formSubmitted: false,
    success: false
};

export const useFormControls = (props: any) => {
    const [values, setValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({} as any);

    const validate: any = (fieldValues = values) => {
        let temp: any = { ...errors };

        if ("fullName" in fieldValues)
            temp.fullName = fieldValues.fullName ? "" : "This field is required.";

        if ("email" in fieldValues) {
            temp.email = fieldValues.email ? "" : "This field is required.";
            if (fieldValues.email)
            temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
                ? ""
                : "Email is not valid.";
        }

        if ("message" in fieldValues)
            temp.message =
            fieldValues.message.length !== 0 ? "" : "This field is required.";

        setErrors({...temp});
    };

    const handleInputValue = (e: any) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
        validate({ [name]: value });
    };

    const handleSuccess = () => {
        setValues({
            ...initialFormValues,
            formSubmitted: true,
            success: true
        });
        props.setSendSuccess(true);
    };

    const handleError = () => {
        setValues({
            ...initialFormValues,
            formSubmitted: true,
            success: false
        });
    };

    const formIsValid = (fieldValues = values) => {
        const isValid =
            fieldValues.fullName &&
            fieldValues.email &&
            fieldValues.message &&
            Object.values(errors).every((x) => x === "");

        return isValid;
    };

    const handleFormSubmit = async (e: any) => {
        e.preventDefault();
        const isValid =
            Object.values(errors).every((x) => x === "") && formIsValid();
        if (isValid) {
            await PostContactForm(values, handleSuccess, handleError);
        }
    };

    return {
        values,
        errors,
        handleInputValue,
        handleFormSubmit,
        formIsValid
    };
};
