
export function Header({ logo, siteTitle, ...props }: any) {
    return (
        <>
            <div>
                <img style={{height: "100px"}} alt={siteTitle} src={logo} />
            </div>
        </>
    );
}

export default Header;